@import './colors';

#page-container {
  & > * {
    width: 100%;
  }

  & > router-outlet {
    width: 0 !important;
  }
}

html, body {
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;

  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  color: $text-color;
  background-color: $right-pane-background;

  overflow-y: auto;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Comfortaa', 'Arial', sans-serif;
  letter-spacing: normal;
  font-weight: 400;

  margin: 0;
}

label {
  margin-bottom: 0;
}

table, .table {
  margin-bottom: 0;
}

.page {
  $pageHeaderHeight: 80px;
  $pageSubHeaderHeight: 50px;

  padding: calc(#{$pageHeaderHeight} + 2rem) 2rem 2rem;

  height: 100%;

  display: flex;
  flex-direction: column;

  position: relative;

  background-color: $right-pane-background;

  &.no-header {
    padding-top: 2rem;
  }

  &.with-sub-header {
    padding-top: calc(#{$pageHeaderHeight} + #{$pageSubHeaderHeight} + 1rem);
  }

  &.page-fluid {
    padding: 0;
  }

  .page-header {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: $pageHeaderHeight;

    padding: 1rem 2rem;

    color: #fff;

    background-image: linear-gradient(90deg, $menu-background, $menu-light-background);

    .mat-form-field {
      .mat-form-field-label {
        color: #fff;
      }

      .mat-input-element {
        caret-color: #fff;
      }

      .mat-form-field-underline {
        background-color: #fff;

        .mat-form-field-ripple {
          background-color: #fff;
        }
      }

      &.mat-focused {

      }
    }
  }

  .page-sub-header {
    height: $pageSubHeaderHeight;
    width: 100%;

    background-color: #fff;

    position: absolute;
    top: $pageHeaderHeight;
    left: 0;

    padding: 0;

    border-bottom: 1px solid rgb(74, 74, 74, 0.2);
  }
}

.cdk-overlay-pane {
  max-width: 95vw !important;
}

.horizontal-radio {
  .mat-radio-label {
    flex-direction: column;

    .mat-radio-label-content {
      padding-left: 0;
      padding-top: 10px;
    }
  }
}

.mat-nav-list {
  padding-top: 0 !important;

  &.left-menu {
    .mat-list-item {
      color: #fff;

      .mat-list-item-content {
        padding: 0 25px;
        min-width: 250px;
      }

      &.active {
        background-color: $menu-active-background;
      }
    }
  }
}

.dialog {
  padding: 2rem;

  min-width: 420px;
}

.card-box {
  position: relative;

  border: 0;
  border-radius: 4px;

  background-color: #fff;

  padding: 2rem 1.5rem;

  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  .card-box-header {
    margin: -2rem -1.5rem 1.5rem;

    padding: 0.5rem 1.5rem;

    background-color: $highlighted-background-color;

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    border-bottom: 1px solid $border-color;
  }

  &.card-box-color- {
    &blue {
      border-top: 10px solid $blue-color;
    }

    &purple {
      border-top: 10px solid $purple-color;
    }

    &orange {
      border-top: 10px solid $orange-color;
    }

    &yellow {
      border-top: 10px solid $yellow-color;
    }
  }
}

.selected-section {
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 4px;
  padding: 20px;

  .selected-section-header {
    color: rgba(0, 0, 0, .54);

    padding: 8px 8px 8px 16px;
    margin: -25px -16px 0 -16px;
  }
}

.border-rounded {
  border-radius: 6px;
}

.w-100-button {
  button {
    display: inline-block;
    width: 100%;
  }
}

.btn-big {
  padding: 0.5rem 1rem !important;

  font-size: 18px;
}
