$primary-color: #1eaefe;
$secondary-color: #81cdef;
$third-color: #08866a;
$success-color: #6aa843;
$warn-color: #f44336;

$title-color: #215068;
$text-soft-color: rgba(0, 0, 0, 0.7);

$text-color: #0a0a0a;
$border-color: #d0cece;
$highlighted-background-color: #efeded;
$hover-highlighted-background-color: #faf8f8;
$background-black: #0a0a0a;

$right-pane-background: #fafafa;

$menu-background: #08866a;
$menu-light-background: #0bbb95;
$menu-active-background: #0b6151;
$menu-active-smooth-background: #0c6c5b;

$blue-color: #1eaefe;
$purple-color: #a67ef6;
$orange-color: #fe7e74;
$yellow-color: #ffc344;

.text-gray {
  color: #444;
}

.text-title {
  color: $title-color;
}

.text-soft {
  color: $text-soft-color;
}

.highlighted {
  background-color: $highlighted-background-color;
}

.left-menu-background {
  color: #fff;
  background-image: linear-gradient(180deg, $menu-background, $menu-light-background);

  --mat-list-active-indicator-color: #{$menu-active-background};
  --mdc-list-list-item-leading-icon-color: #fff;
  --mdc-list-list-item-hover-label-text-color: #fff;
  --mdc-list-list-item-focus-label-text-color: #fff;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: #fff;

  .mat-mdc-list-item,
  .mat-mdc-list-item a,
  .mat-nav-list a {
    color: #fff;
    text-decoration: none;
  }

  .mat-mdc-list-item {
    &.active {
      background-color: $menu-active-background;
    }
  }
}

.pane-background {
  background-color: $right-pane-background;
}

.bg-menu-active-background {
  background-color: $menu-active-background;
}

.bg-menu-active-smooth-background {
  background-color: $menu-active-smooth-background;
}

.bg-blue {
  background-color: $blue-color !important;
}

.bg-purple {
  background-color: $purple-color !important;
}

.bg-orange {
  background-color: $orange-color !important;
}

.bg-yellow {
  background-color: $yellow-color !important;
}

.color-third {
  color: $third-color;
}

.color-success {
  color: $success-color;
}
