@use '../colors' as colors;
@use '@angular/material' as mat;

@include mat.core();

$work-leaps-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$azure-palette,
    tertiary: mat.$magenta-palette,
  ),
  //typography: mat.m2-define-typography-config(),
  density: (
    scale: 0
  )
));

html {
  @include mat.core-theme($work-leaps-theme);

  @include mat.button-theme($work-leaps-theme);
  @include mat.fab-theme($work-leaps-theme);

  @include mat.input-theme($work-leaps-theme);
  @include mat.input-density(-1);

  @include mat.form-field-theme($work-leaps-theme);
  @include mat.input-density(-1);

  @include mat.checkbox-theme($work-leaps-theme);
  @include mat.menu-theme($work-leaps-theme);
  @include mat.progress-spinner-theme($work-leaps-theme);
  @include mat.button-toggle-theme($work-leaps-theme);
  @include mat.table-theme($work-leaps-theme);
  @include mat.tabs-theme($work-leaps-theme);
  @include mat.autocomplete-theme($work-leaps-theme);
  @include mat.list-theme($work-leaps-theme);
  @include mat.icon-theme($work-leaps-theme);
  @include mat.datepicker-theme($work-leaps-theme);
  @include mat.select-theme($work-leaps-theme);
}

.mat-warn {
  --mdc-filled-button-container-color: #{colors.$warn-color};
}

.mat-mdc-icon-button {
  --mdc-icon-button-icon-size: 14px;

  .icon-sm {
    --mdc-icon-button-icon-size: 7px;
  }
}

.form-field-small {
  @include mat.form-field-density(-10);
  @include mat.input-density(-10);
  @include mat.autocomplete-density(-10);
  --mat-form-field-container-vertical-padding: 12px;
}
